import i18n from "@/libs/i18n";
import Vue from 'vue'
const fields = [
    {
        key: 'name',
        label: i18n.t('user'),
        formatter: (value, key, item) => value ?? item.user.name
    },
    {
        key: 'email',
        label: i18n.t('email'),
        formatter: (value, key, item) => `${value ?? item.user.email} <br> ${item.ip_address}`
    },
    {
        key: 'comment',
        label: i18n.t('comment'),
        formatter: (value) => {
            return Vue.prototype.$stripTags(value).substr(0, 40) + '...'
        }
    },
    {
        key: 'status',
        label: i18n.t('status'),
    },
    {
        key: 'created_at',
        label: i18n.t('created_at'),
    },
    {
        key: 'action',
        label: i18n.t('action'),
    },
]

export default fields