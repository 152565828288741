<template>
    <div>
        <div class="d-flex justify-content-between align-items-center mb-1">
            <div>
                <b-button class="mr-1" variant="info" @click="visible = !visible">
                    {{ $t('search') }}
                </b-button>
            </div>
            <div>
                <span>تعداد کل: </span>
                {{ pagination.total }}
            </div>
        </div>
        <custom-filter
            :filters="filters"
            :visible="visible"
            @onSubmit="filters => filter(filters)"
        >
            <template #form="{ filters }">
                <b-row>
                    <b-col md="2">
                        <custom-select
                            v-model="filters.status"
                            :name="$t('status')"
                            :options="commentStatusOptions"
                            vid="status"
                        />
                    </b-col>
                    <b-col md="4">
                        <custom-input
                            v-model="filters.email"
                            :name="$t('email')"
                            vid="email"
                        />
                    </b-col>
                    <b-col md="3">
                        <custom-input
                            v-model="filters.ip_address"
                            :name="$t('ip_address')"
                            vid="ip_address"
                        />
                    </b-col>
                </b-row>
            </template>
        </custom-filter>
        <b-card no-body>
            <b-table :busy="status === 'LOADING'" :fields="fields" :items="Object.values(comments)" hover responsive>
                <template #table-busy>
                    <div class="text-center text-danger my-2">
                        <b-spinner class="align-middle"/>
                    </div>
                </template>
                <template #cell(status)="data">
                    <b-badge :variant="statusColor(data.item.status)">
                        {{ statusText(data.item.status) }}
                    </b-badge>
                </template>
                <template #cell(email)="data">
                    <span v-html="data.value"></span>
                </template>
                <template #cell(action)="data">
                    <b-dropdown no-caret variant="link">
                        <template #button-content>
                            <feather-icon icon="MoreHorizontalIcon"/>
                        </template>
                        <b-dropdown-item :to="{name: 'edit-comment', params:{id: data.item.id}}">
                            <feather-icon icon="Edit2Icon"/>
                            {{ $t('edit') }}
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>
            <b-pagination
                v-if="pagination.last_page > 1"
                v-model="pagination.current_page"
                :per-page="pagination.per_page"
                :total-rows="pagination.total"
                align="center"
                class="mt-2"
                @change="paginationComment"
            />
        </b-card>
    </div>
</template>

<script>
import fields from "./partials/fields";
import { mapActions, mapGetters } from "vuex";
import CommentStatus from "@/constans/CommentStatus";
import { commentStatusOptions } from "@/common/data";

export default {
    name: "Comments",

    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.fetchComments()
        })
    },

    data() {
        return {
            fields,
            visible: false,
            commentStatusOptions,
            filters: {
                status: '',
                email: '',
                ip_address: '',
            }
        }
    },

    computed: {
        ...mapGetters('comment', [
            'comments', 'status', 'pagination'
        ]),
        ...mapGetters('filter', [
            'filterFields'
        ])
    },

    methods: {
        ...mapActions({
            fetchComments: 'comment/fetch',
        }),
        filter(filters) {
            this.fetchComments({
                ...filters,
                page: 1
            })
        },
        paginationComment(page) {
            this.fetchComments({
                page,
                ...this.filterFields
            })
        },
        statusColor(value) {
            if (value === CommentStatus.PENDING) return 'warning'
            if (value === CommentStatus.APPROVED) return 'success'
            if (value === CommentStatus.UNAPPROVED) return 'danger'

        },
        statusText(value) {
            if (value === CommentStatus.PENDING) return this.$t('pending')
            if (value === CommentStatus.APPROVED) return this.$t('approved')
            if (value === CommentStatus.UNAPPROVED) return this.$t('unapproved')
        }
    }
}
</script>
